<template>
  <div class="flex">
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0 color-dark">
            {{ greetingsTime }} {{ $store.state.auth.userData.full_name || $store.state.auth.userData.username }}
          </p>
          <span class="user-status">{{ $store.state.auth.userData.role }}</span>
        </div>
        <b-avatar
          size="40"
          :src="$store.state.auth.userData.photo_profile_url"
          variant="light-primary"
          badge
          class="badge-minimal"
          badge-variant="success"
        >
          <feather-icon
            v-if="!$store.state.auth.userData.photo_profile_url"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="skin = isDark ? 'light' : 'dark'"
      >
        <feather-icon
          size="16"
          :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
          class="mr-50"
        />
        <span>{{ isDark ? 'Light' : 'Dark' }} Mode</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <div
      v-if="mitra_type === 'Warehouse'"
      class="mr-3 ml-50"
    >
      <b-button
        size="sm"
        variant="flat-primary"
        class="btn-icon position-relative"
        @click="showNotification"
      >
        <b-img src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/notification-1.svg" />
        <div
          v-if="notificationTotal > 0"
          class="wrapper-notification-count"
        >
          <span class="text-white font-semibold">{{ notificationTotal }}</span>
        </div>
      </b-button>
      <b-modal
        id="modal-notification"
        ref="modal-notification"
        modal-class="myclass"
        hide-footer
        hide-header
      >
        <div class="flex justify-content-between align-items-center p-2 border-b">
          <div>
            <div class="">
              <strong class="text-black text-xl">
                Notifications
              </strong>
              <span
                v-if="notificationTotal > 0"
                class="bg-[#FFECE9] rounded-xl text-[#F95031] px-[8px] text-sm py-[2px] ml-[4px]"
              >
                {{ notificationTotal }} New
              </span>
            </div>
          </div>
          <div>
            <b-img
              src="https://storage.googleapis.com/komerce/assets/icons/close-circle.svg"
              class="cursor-pointer"
              @click="$bvModal.hide('modal-notification')"
            />
          </div>
        </div>
        <div class="wrapper-content-notification">
          <div
            v-if="listNotification.length !== 0"
          >
            <div
              v-for="(item, index) in listNotification"
              :key="index + 1"
              :style="handleNotifBg(item.is_read)"
              class="flex cursor-pointer py-[12px]"
              :class="index !== 0 ? 'border-t' : ''"
              @click="readNotif(item)"
            >
              <b-col cols="2">
                <b-img
                  center
                  width="40"
                  :src="item.image_path"
                />
              </b-col>
              <b-col
                cols="10"
                class="d-flex flex-column w-full"
              >
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <span class="text-black font-semibold">
                    {{ item.title }}
                  </span>
                  <small
                    class="font-semibold text-[#626262]"
                  >
                    {{ getFormatDate(item.created_at) }}
                  </small>
                </div>
                <small
                  class="text-[#626262]"
                  v-html="item.description"
                />
              </b-col>
            </div>
          </div>
          <div v-else>
            <div class="flex justify-content-center align-items-center h-[300px]">
              Tidak ada notifikasi yang perlu dibaca
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import moment from 'moment'
import { avatarText } from '@core/utils/filter'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { isUserLoggedIn } from '@/auth/utils'
import secureLocalStorage from '@/libs/secureLocalstorage'
import { kompackAxiosIns } from '@/libs/axios'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,

      notificationTotal: 0,
      listNotification: [],

      mitra_type: secureLocalStorage.get('userData').mitras.mitra_type,
    }
  },
  computed: {
    isAdmin() {
      return isUserLoggedIn() && this.$store.state.auth?.userData?.role_name?.toUpperCase() === 'ADMIN'
    },
    profileRoute() {
      if (!isUserLoggedIn()) return ''
      if (this.$store.state.auth?.userData?.role_name?.toUpperCase() === 'SDM') return 'talent-profile'
      if (this.$store.state.auth?.userData?.role_name?.toUpperCase() === 'PARTNER') return 'partner-profile'
      return ''
    },
    greetingsTime() {
      const date = new Date()
      let dateChange = null
      let str = ''
      if ((date.getUTCHours() - date.getHours()) !== 0) {
        dateChange = date.getHours()
      } else {
        dateChange = (8 + date.getHours())
      }

      if (dateChange < 4) {
        str = 'Selamat Malam,'
      } else if (dateChange < 11) {
        str = 'Selamat Pagi,'
      } else if (dateChange < 16) {
        str = 'Selamat Siang,'
      } else if (dateChange < 20) {
        str = 'Selamat Sore,'
      } else {
        str = 'Selamat Malam,'
      }
      return str
    },
  },
  created() {
    if (this.mitra_type === 'Warehouse') {
      this.getListNotification()
    }
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  methods: {
    logout() {
      localStorage.clear()
      secureLocalStorage.clear('userData')
      secureLocalStorage.clear('accessToken')
      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    getFormatDate(value) {
      const now = moment()
      const createdAt = moment(value)

      const diffMinutes = now.diff(createdAt, 'minutes')
      const diffHours = now.diff(createdAt, 'hours')

      if (diffMinutes < 60) {
        return `${diffMinutes} menit yang lalu`
      } if (diffHours < 2) {
        return 'satu jam yang lalu'
      } if (diffHours >= 2 && createdAt.isSame(now, 'day')) {
        return `${diffHours} jam yang lalu`
      } if (createdAt.isSame(now.clone().subtract(1, 'day'), 'day')) {
        return `Kemarin ${createdAt.format('HH.mm')}`
      }
      return createdAt.format('DD MMMM YYYY HH.mm')
    },
    showNotification() {
      this.$refs['modal-notification'].show()
    },
    getListNotification() {
      kompackAxiosIns.get('/v1/notification/list')
        .then(response => {
          const { data } = response.data
          this.notificationTotal = data.total_notification
          this.listNotification = data.data
        })
    },
    readNotif(data) {
      kompackAxiosIns.post(`/v1/notification/read/${data.id}`)
        .then(() => {
          this.getListNotification()
        })
      if (data.notification_type === 'unsubscribe') {
        this.$refs['modal-notification'].hide()
        this.$router.push('/outbound/pengeluaran-barang?tab=menunggu-response')
      }
    },
    handleNotifBg(isRead) {
      if (isRead === 0) {
        return 'background: #FFECE9;'
      }
      return 'background: white;'
    },
  },
}
</script>
<style scoped>
::v-deep .myclass > .modal-dialog {
  position: unset;
  transform: none!important;
}
::v-deep .myclass > .modal-dialog > .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  width: 500px;
  margin: 5rem 1rem;
}
::v-deep .myclass > .modal-dialog > .modal-content .modal-body {
  padding: 0px!important;
}
.wrapper-content-notification {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 380px;
}
.wrapper-notification-count {
  background: #F95031;
  height: 24px;
  min-width: 28px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  top: -5px;
  right: -5px;
}
.icon-level {
  width: 32px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
